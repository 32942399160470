import React, {useState} from 'react';
import { Outlet, Link, useNavigate, useMatch, useResolvedPath } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiDrawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Avatar from "@mui/material/Avatar/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar/ListItemAvatar";
import Collapse from '@mui/material/Collapse';
import {ExpandLess, Help, ExpandMore} from '@mui/icons-material';
import Modal from "@mui/material/Modal/Modal";
import menuAlertData from "./menuAlertData";
import Slide from "@mui/material/Slide/Slide";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Paper from "@mui/material/Paper/Paper";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import Fade from "@mui/material/Fade/Fade";
import IconButton from '@mui/material/IconButton';
// import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';

// const drawerWidth = 300;


const menuData = [
  {
    icon: require('../static/images/menu/icon_ewh_nor@3x.png'),
    icon2: require('../static/images/menu/icon_ewh_sel@3x.png'),
    title: 'Satellite Gravimetry Mass Changes',
    // subTitle: 'Equivalent Water Height',
    // path: 'ewh',
    children: [
      {
        icon: require('../static/images/menu/icon_ewh_nor@3x.png'),
        icon2: require('../static/images/menu/icon_ewh_sel@3x.png'),
        title: 'GRACE/GRACE-FO',
        subTitle: 'GRACE/GRACE-FO daily-sampled terrestrial water storage anomaly (TWSA, 666 km), Jun 2018–Nov 2022',
        path: 'grace',
      },

      {
        icon: require('../static/images/menu/icon_ewh_nor@3x.png'),
        icon2: require('../static/images/menu/icon_ewh_sel@3x.png'),
        title: 'DL-aided Simulation',
        subTitle: 'Deep-Learning aided downscaled (25 km) monthly satellite gravimetry TWSA, 2003–2021',
        path: 'ewhDeep',
      },
    ]
  },

  {
    icon: require('../static/images/menu/icon_igbp_nor@3x.png'),
    icon2: require('../static/images/menu/icon_igbp_sel@3x.png'),
    title: '4-m Resolution Land Use Land Cover 4-m Classification, 2014–2022',
    // subTitle: 'MODIS land cover type (MCD12Q1, IGBP scheme)',
    // path: 'igbp',
    children: [
      {
        icon: require('../static/images/menu/icon_igbp_nor@3x.png'),
        icon2: require('../static/images/menu/icon_igbp_sel@3x.png'),
        title: 'DL-aided LULC',
        subTitle: 'DL-aided LULC classification from Planet-NICFI basemaps',
        path: 'classification',

      },
      {
        icon: require('../static/images/menu/icon_igbp_nor@3x.png'),
        icon2: require('../static/images/menu/icon_igbp_sel@3x.png'),
        title: 'MODIS Land Cover Type Reference for Classification',
        subTitle: 'Terra and Aqua combined Moderate Resolution Imaging Spectroradiometer (MODIS) Land Cover Type (MCD12Q1), IGBP scheme',
        path: 'igbp',
      }
    ]
  },
  {
    icon: require('../static/images/menu/icon_srtm_nor.png'),
    icon2: require('../static/images/menu/icon_srtm_sel.png'),
    title: 'Planet Imageries Detection of Chamoli, India Glacial Avalanches, February 2021',
    // subTitle: 'Planet Imageries Detection of Chamoli, India Glacial Avalanches, February 2021',
    path: 'dem',
    noHelp: true,
  },
  {
    icon: require('../static/images/menu/icon_water_nor@3x.png'),
    icon2: require('../static/images/menu/icon_water_sel@3x.png'),
    title: 'Inland Water Levels',
    subTitle: 'Water levels from multi-mission satellite radar altimetry',
    path: 'water',
  },
  {
    icon: require('../static/images/menu/icon_height_nor@3x.png'),
    icon2: require('../static/images/menu/icon_height_sel@3x.png'),
    title: 'Global Forest Canopy Height',
    subTitle: 'Hofton(2020) Mapping and monitoring global forest canopy height through integration of GEDI and Landsat data.',
    path: 'gfch',
  },
  {
    icon: require('../static/images/menu/icon_height_nor_2@3x.png'),
    icon2: require('../static/images/menu/icon_height_sel_2@3x.png'),
    title: 'Forest Aboveground Biomass',
    subTitle: 'GEDI gridded aboveground biomass density (1 km)',
    path: 'bionmass',
  },
  {
    icon: require('../static/images/menu/icon_flooddata_nor@3x.png'),
    icon2: require('../static/images/menu/icon_flooddata_sel@3x.png'),
    title: 'Global Floods',
    subTitle: 'Floods monitoring using PlanetScope',
    path: 'flood',
  },
  {
    icon: require('../static/images/menu/icon_fire_nor@3x.png'),
    icon2: require('../static/images/menu/icon_fire_sel@3x.png'),
    title: 'VIIRS Fire',
    subTitle: 'Data or image from NASA\'s Fire Information for Resource Management System(FIRMS)',
    path: 'fire',
  },
  {
    icon: require('../static/images/menu/wt_nor@3x.png'),
    icon2: require('../static/images/menu/wt_sel@3x.png'),
    title: 'Monthly and Daily Deep Learning-aided Terrestrial Water Storage simulation',
    // subTitle: 'Monthly and Daily Deep Learning-aided Terrestrial Water Storage simulation',

    children: [
      {
        title: 'Daily',
        // subTitle: 'DL-aided LULC classification from Planet-NICFI basemaps',
        path: 'terrestrial/daily',
        noHelp: true,
      },
      {
        title: 'Monthly',
        // subTitle: 'Terra and Aqua combined Moderate Resolution Imaging Spectroradiometer (MODIS) Land Cover Type (MCD12Q1), IGBP scheme',
        path: 'terrestrial/monthly',
        noHelp: true,
      }
    ]
  },
  // {
  //   icon: require('../static/images/menu/icon_data_nor@3x.png'),
  //   icon2: require('../static/images/menu/icon_data_sel@3x.png'),
  //   title: 'Equivalent Water Height',
  //   // subTitle: 'Data or image from NASA\'s Fire Information for Resource Management System(FIRMS)',
  //   path: 'test',
  // },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function App() {
  const [menuOpen, setMenuOpen] = React.useState({'menu-0': true, 'menu-1': true, 'menu-8': true});
  const handleHelpClick = (path) => (event) => {
    event.stopPropagation();
    console.log(path);
    setAlertData(path);
  }
  const [alertData, setAlertData] = useState();
  const [open, setOpen] = useState(true);
const [drawerWidth, setDrawerWidth] =  useState(300);
  const handleClose = () => {
    setAlertData(null)
  }
  const handleDrawerClose =() => {
    setDrawerWidth(0)
    setOpen(false)
  };
  const handleDrawerOpen =() =>{
    setDrawerWidth(300)
    setOpen(true)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              style={{position:'fixed',top:'10px',left:'20px',zIndex:'9999999', background:'#fff'}}
              sx={{ mr: 2, ...(open && { display: 'none' ,}) }}
            >
              <MenuIcon />
            </IconButton>
      <Drawer
        variant="permanent"

        sx={{
          transition: 'width 1s ease',
          width: drawerWidth,
          flexShrink: 0,
          overflow: 'hidden',
          [`& .MuiDrawer-paper`]: { width: drawerWidth,transition: 'width 1s ease', boxSizing: 'border-box' },
        }}
      >
        <AppBar position="relative" sx={{ background: '#fff', zIndex: 1 }}>
          <Toolbar style={{display:'flex',justifyContent:'space-between'}} >
            <Typography sx={{ color: '#000', fontSize: '1rem' }} variant="h6" noWrap component="div">
              Data Products
            </Typography>
            <IconButton  onClick={handleDrawerClose}>
               <ChevronLeftIcon />
            </IconButton>
            
          </Toolbar>
        </AppBar>
        {/*<Toolbar />*/}
        <Box sx={{ overflow: 'auto', }}>
          <List
            sx={{
              pt: 2,
              pb: 2,
            }}
          >
            {
              menuData.map((item, index) => (
                item.children ? (
                    <ChildMenu
                      childrenData={item.children}
                      item={item}
                      key={'menu-' + index}
                      keyValue={'menu-' + index}
                      menuOpen={menuOpen}
                      setMenuOpen={setMenuOpen}
                      handleHelpClick={handleHelpClick}
                    />
                  ) : (
                    <CustomLink item={item} key={'menu-' + index} handleHelpClick={handleHelpClick}/>
                  )
              ))
            }
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 0, position: 'relative', zIndex: 1, background: '#fff' }}>
        <Outlet />
        <Box sx={{zIndex:'999999',textAlign:'center', position:'absolute' ,left:'0',right:'34px', bottom:'5px' ,right:'0', textAlign:'center',fontSize:'10px',color:'#fff',textShadow: '0 0 2px #000000',fontFamily:' sans-serif'}}>USAID India Forest Sustainability Project, REFRESH, 2021–2024 (Beta Version 1.1)</Box>
      </Box>
      {
        alertData && menuAlertData[alertData] ? (
          <Modal
            open={!!alertData}
            transitioncomponent={Transition}
            onClose={handleClose}

            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            // maxWidth='md'
          >
            <Slide  direction="up" in={!!alertData}  mountOnEnter unmountOnExit>
              <Box
                sx={{
                  width: '100%',
                  position: 'relative',
                  height: '100vh',
                  overflowY: 'scroll',
                  // top: '10%'
                }}
              >
                <Paper
                  sx={{
                    position: 'relative',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 600,
                  }}
                >
                  <DialogTitle id="alert-dialog-slide-title">
                    Description/Citation
                  </DialogTitle>
                  <DialogContent>
                    {
                      menuAlertData[alertData].txt.split(/\n/).map((item, index) => {
                        return (
                          <Typography variant="body1" key={'i-' + index}>
                            {item}
                          </Typography>
                        )
                      })
                    }
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Paper>
              </Box>

            </Slide>

          </Modal>
        ) : null
      }
    </Box>
  );
}

function CustomLink({ children, item, handleHelpClick}) {
  const navigate = useNavigate();

  let resolved = useResolvedPath(item.path);
  let match = useMatch({ path: resolved.pathname, end: true });

  const handleLink = (path) => () => {
    navigate(path);
  }

  return (
    <ListItem
      selected={!!match}
      button
      key={'menu-' + item.title} onClick={handleLink(item.path)}
      sx={{
        // borderRadius: '5px',
      }}
    >
      <ListItemAvatar
        sx={{
          minWidth: 40
        }}
      >
        <Avatar sx={{ width: 30, height: 30 }} alt={item.title} src={match ? item.icon2 : item.icon} />
      </ListItemAvatar>
      <ListItemText
        sx={{
          '& .MuiListItemText-primary': {
            color: match ? '#096DD9' : '#A4AEB2',
            fontSize: '14px',
          },
          '& .MuiListItemText-secondary': {
            color: match ? '#096DD9' : '#A4AEB2',
            fontSize: '12px',
          },
        }}
        primary={item.title}
        secondary={item.subTitle}
      />
      {
        !item.noHelp ? (
          <ListItemIcon
            style={{
              minWidth: '10px',
            }}
            onClick={handleHelpClick(item.path)}
          >
            <Help />
          </ListItemIcon>
        ) : null
      }
    </ListItem>
  );
}

function InnerCustomLink({ children, item, handleHelpClick}) {
  const navigate = useNavigate();

  let resolved = useResolvedPath(item.path);
  let match = useMatch({ path: resolved.pathname, end: true });

  const handleLink = (path) => () => {
    navigate(path);
  }

  return (
    <ListItem
      selected={!!match}
      button
      key={'menu-' + item.title} onClick={handleLink(item.path)}
      sx={{
        borderRadius: '5px',
      }}
    >
      <ListItemText
        sx={{
          '& .MuiListItemText-primary': {
            color: match ? '#096DD9' : '#A4AEB2',
            fontSize: '14px',
          },
          '& .MuiListItemText-secondary': {
            color: match ? '#096DD9' : '#A4AEB2',
            fontSize: '12px',
          },
        }}
        primary={item.title}
        secondary={item.subTitle}
      />
      {
        !item.noHelp ? (
          <ListItemIcon
            style={{
              minWidth: '10px',
            }}
            onClick={handleHelpClick(item.path)}
          >
            <Help />
          </ListItemIcon>
        ) : null
      }
    </ListItem>
  );
}


const ChildMenu = ({ children, childrenData, item, menuOpen, keyValue, setMenuOpen, handleHelpClick}) => {
  const navigate = useNavigate();

  const handleLink = () => {
    setMenuOpen({
      ...menuOpen,
      [keyValue]: !menuOpen[keyValue],
    });
  };


  const open = !!menuOpen[keyValue];

  return (
    <React.Fragment>
      <ListItem
        selected={open}
        button
        key={'menu-' + item.title}
        onClick={handleLink}
        sx={{
          borderRadius: '5px',
        }}
      >

        {open ? <ExpandLess sx={{color: '#096DD9'}} /> : <ExpandMore sx={{color: '#A4AEB2'}} />}
        <ListItemAvatar
          sx={{
            minWidth: 40
          }}
        >
          <Avatar sx={{ width: 30, height: 30 }} alt={item.title} src={open ? item.icon2 : item.icon} />
        </ListItemAvatar>
        <ListItemText
          sx={{
            '& .MuiListItemText-primary': {
              color: open ? '#096DD9' : '#A4AEB2',
              fontSize: '14px',
            },
            '& .MuiListItemText-secondary': {
              color: open ? '#096DD9' : '#A4AEB2',
              fontSize: '12px',
            },
          }}
          primary={item.title}
          secondary={item.subTitle}
        />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          sx={{
            p: 2,
          }}
        >
          {childrenData && childrenData.map((item, index) => {
            return (
              <InnerCustomLink item={item} key={'menu-' + index}  handleHelpClick={handleHelpClick}/>
            )
          })}
        </List>
      </Collapse>
    </React.Fragment>
  )

}


/*
* <h1>Bookkeeper</h1>
      <nav style={{ borderBottom: 'solid 1px', paddingBottom: '1rem' }}>
        <Link to="/invoices">Invoices</Link> |{' '}
        <Link to="/expenses">Expenses</Link>
      </nav>
      <Outlet />
    </div>
* */
